import * as React from 'react';
import QrReader from 'react-qr-scanner'

import { Helmet } from "react-helmet";
class UsersOut extends React.Component{

    constructor(props){
        super(props)
        this.state = {
          delay: 100,
          result: '',
        }
        this.handleScan = this.handleScan.bind(this)
    }

    handleScan(data){
        if(data !== null){
            if(this.state.result !== data.text)
            {
                this.setState({
                    result: data.text
                });
                var beepSound = new Audio('./assets/beep.mp3');
                beepSound.loop = false;
                beepSound.play();
            }
            
        }
    }

    handleError(err){
    console.log(err)
    }

    validateTicket = () => {
        return false;
    }

    render(){
        const previewStyle = {
            height: '100%',
            width: '100%',
          }
        return (
        <>
            <Helmet>
                <title>Home</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                
                <body id="page-top"></body>
            </Helmet>
            <h1 className="h3 mb-2 text-gray-800">Check out</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Scan individual Check Out QR code</h6>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='qrscanner'>
                                <div className="table-responsive">
                                    <QrReader
                                        delay={this.state.delay}
                                        style={previewStyle}
                                        onError={this.handleError}
                                        onScan={this.handleScan}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <div className='input-group'>
                                    <input type='text' onChange={this.validateTicket} className='form-control' value={this.state.result} />
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
        )
    }
    
}

export default UsersOut