import * as React from 'react';

class Footer extends React.Component {
    
    

    render() {
      return (
        <>
        <footer className="sticky-footer bg-white no-print">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; Sri Sathya Sai DivyaSmrti</span>
                    </div>
                </div>
        </footer>
        </>
      );
    }
  }
  export default Footer;