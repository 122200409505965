import * as React from 'react';

import { Helmet } from "react-helmet";
import '../../assets/vendor/fontawesome-free/css/all.min.css';
import '../../assets/css/sb-admin-2.css';
import { Link } from 'react-router-dom';

class NotFound extends React.Component {
    
    
    render() {
      
      return (
        <div>
            <Helmet>
          
                <title>Error 404 |  Page Not Found</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>
                
                </Helmet>
                <div className="login-container  bg-gradient-primary">

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-xl-10 col-lg-12 col-md-9">

                                <div className="card o-hidden border-0 shadow-lg my-5">
                                    <div className="card-body p-0">
                                        
                                        <div className="row">
                                            <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                                <img alt='Sai' title='Sai' src='https://beta.prasanthinilayam.in/images/swamisitting.jpg' style={{maxWidth:'100%'}} />
                                            </div>
                                            <div className="col-lg-6 align-self-center">
                                                <div className="p-5">
                                                    <div className="text-center">
                                                        <h1 className="h4 text-gray-900 mb-4">Error 404!</h1>
                                                    </div>
                                                    <div>Page Not Found</div>
                                                    <hr />
                                                    <div className="text-center">
                                                        <Link className="small" to="/">Go to Home</Link>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <script src="./assets/vendor/jquery/jquery.min.js"></script>
                    <script src="./assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
                    <script src="./assets/vendor/jquery-easing/jquery.easing.min.js"></script>
                    <script src="./assets/js/sb-admin-2.min.js"></script>

                </div>
        </div>
      );
    }
  }
  export default NotFound;