import { doc, setDoc, getDocs, query as fquery, collection, orderBy as forderBy, limit, deleteDoc } from "firebase/firestore"; 
import { ref, set, orderByKey, get, remove, query as dbquery, limitToLast } from "firebase/database";
import Config from "./Init";

class Database extends Config{
    
    constructor(props){
        super(props);
        this.firedata = false;
    }

    _set = async (col, key, data) => {
        if(this.firedata){
            const rf = doc(this.fdb, col, key);
            return await setDoc(rf, data);
        }else{
            const rf = ref(this.db, '/' + col + '/' + key);
            return await set(rf, data);
        }
        
    }

    _fetchResult = (snaps) => {
        if(this.firedata){
            let data = [];
            snaps.forEach((e)=>{
                data[(e.data().Date + ' ' + e.data().Slot)] = e.data();
               // data.push(e.data());
            });
            return data;
        }else{
            if (snaps.exists()) {
                return snaps.val();
            } else {
                return false;
            }
        }
    } 

    _remove = async (col, key) => {
        if(this.firedata){
            const rf = doc(this.fdb, col, key);
            return await deleteDoc(rf);
        }else{
            const rf = ref(this.db, col + '/' + key);
            return await remove(rf);
        }
    }

    _getAll = async (col, order = false, l = false) => {
        if(!this.firedata){
            let q = dbquery(ref(this.db, col));
            
            if(order && l){
                q = dbquery(ref(this.db, col), orderByKey(order[0],order[1]), limitToLast(l));
                
            }else if(!order && l){
                q = dbquery(ref(this.db, col));     
            }else if(order && !l){
                q = dbquery(ref(this.db, col));
            }
            return await get(q);
        }else{
            let q = fquery(collection(this.fdb, col));
            if(order && l){
                q = fquery(collection(this.fdb, col), forderBy(order[0],order[1]), limit(l));
                
            }else if(!order && l){
                q = fquery(collection(this.fdb, col), limit(l));      
            }else if(order && !l){
                q = fquery(collection(this.fdb, col), forderBy(order[0],order[1]));
            }
            return await getDocs(q);
        }
        
    }
}

export default Database;